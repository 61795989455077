<template>
  <div>
    <b-row>
      <b-col>
        <b-card
          no-body
        >
          <b-card-header>
            <b-card-title>
              Acquisition experiment
            </b-card-title>
            <b-button
              variant="outline-primary"
              @click="setupNewExperiment"
            >Create new experiment</b-button>
          </b-card-header>
        </b-card>
      </b-col>
    </b-row>

    <!-- Searchbar -->
    <div
      class="ecommerce-searchbar"
    >
      <b-row v-if="isEmpty">
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="Search experiment"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!--- List of experiments -->
    <section class="list-view mt-2">
      <experiment-overview
        v-for="(experiment, index) in filteredExperiments"
        :key="index"
        :experiment="experiment"
        class="mb-2 ecommerce-card"
      />
    </section>

    <b-sidebar
      id="sidebar-experiment-links"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      title="Links"
      backdrop
      lazy
      right
      @shown="onSidebarShown"
      @hidden="onSidebarHidden"
    >
      <template #default="">

        <experiment-variants-view
          class="m-1 border rounded"

          :variants="selectedExperiment.efforts"
          :is-edit-mode="false"
          :experiment-name="selectedExperiment.name"
        />

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BSidebar,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import ExperimentOverview from './list/ExperimentOverview.vue'
import ExperimentVariantsView from './experiment/ExperimentVariantsView.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    ExperimentOverview,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BSidebar,
    ExperimentVariantsView,
  },
  data() {
    return {
      filters: {
        q: '',
      },
    }
  },
  computed: {
    ...mapState('experimentList', {
      experiments: state => state.experiments,
      selectedExperiment: state => state.selectedExperiment,
    }),
    filteredExperiments() {
      const { q } = this.filters
      return this.experiments.filter(experiment => {
        // Filter all atributes by search query
        const query = Object.values(experiment).reduce((acc, curr) => (typeof curr === 'string' ? acc + curr : acc), '')
        return query.includes(q)
      })
    },
    isEmpty() {
      return this.experiments.length > 0
    },
  },
  created() {
    // Fetch experiments
    this.load()
  },
  mounted() {
    // subscribe to changes
    this.$eventBus.$on('filterChanges', () => {
      this.load()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('filterChanges')
  },
  methods: {
    ...mapActions('experimentList', {
      load: 'fetchExperiments',
    }),
    setupNewExperiment() {
      this.$router.push({ name: 'newExperiment' })
    },
    onSidebarShown() {
      const body = document.getElementsByTagName('body')[0]
      body.classList.add('overflow-hidden')
    },
    onSidebarHidden() {
      const body = document.getElementsByTagName('body')[0]
      body.classList.remove('overflow-hidden')
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
  @import '~@core/scss/base/components/include'; // Components includes

  $search-bar-height: 48px;

  // Product search bar
  .ecommerce-searchbar {
    .input-group {
      box-shadow: 0 2px 8px 0 rgba(#000, 0.14);
      border-radius: 0.3rem;
    }
  }

  // Search bar
  .search-product,
  .input-group-text {
    height: $search-bar-height;
    border: none;
    font-size: 0.95rem;
    padding-left: 1.25rem;

    &::placeholder {
      font-size: 0.95rem;
    }
  }
</style>
<style lang="scss">
  #sidebar-experiment-links{
    width: 50%;
    min-width: 60em;
    .b-sidebar-body{
      overflow-x: hidden;
    }

    @media screen and (max-width: 991px) {
      width: 90%;
      min-width: 0em;
    }
  }
</style>
