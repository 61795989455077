<template>

  <b-card>
    <b-row>
      <b-col lg="3">
        <h4>
          {{ experiment.name }} |
          <small> {{ experiment.startDate | formatDate }} - {{ experiment.endDate | formatDate }}</small>
        </h4>
        <span class="p-2">
          {{ experiment.description }}
        </span>
      </b-col>
      <b-col lg="5">
        <experiment-performance
          :chart="experiment.chart"
          render-serie="conversion_rate"
        />
      </b-col>
      <b-col
        lg="4"
        class="d-flex flex-column"
      >
        <b-row>
          <b-col class="text-right">
            <b-badge
              v-if="goalStatus"
              v-b-tooltip.hover
              :variant="goalStatusBadgeVariant"
              :title="`Experiment status: ${goalStatus}`"
              class="badge-glow px-1 mx-1"
            >
              {{ goalStatus }}
            </b-badge>

            <b-badge
              v-if="goal"
              v-b-tooltip.hover
              variant="light-primary"
              :title="`Experiment goal: ${goal.name}`"
              class="badge-glow px-1 mx-1"
            >
              {{ goal.name }}
            </b-badge>
            <b-button
              v-b-tooltip.hover
              variant="flat-primary"
              title="Edit experiment"
              class="btn-icon"
              :to="{ name: 'editExperiment', params: { id: experiment.id }, query: $route.query }"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </b-col>
        </b-row>
        <experiment-stats
          class="flex-grow-1"
          :values="metrics"
        />
      </b-col>
    </b-row>
    <b-row class="text-right flex-shrink-1">
      <b-col>
        <b-button
          v-b-tooltip.hover
          v-b-toggle.sidebar-experiment-links
          variant="outline-primary"
          class="mx-1"
          title="View experiment links"
          @click="onGetLinksClick"
        >
          <feather-icon
            icon="LinkIcon"
            class="mr-50"
          />
          Links
        </b-button>
        <b-button
          v-b-tooltip.hover
          class="mx-1"
          variant="outline-primary"
          :to="{ name: 'experimentFullView', params: { id: experiment.id }, query: $route.query }"
          title="Show the experiment analytics"
        >
          <feather-icon
            icon="TrendingUpIcon"
            class="mr-50"
          />
          Analytics
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BBadge, VBTooltip, VBToggle,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ExperimentPerformance from './ExperimentPerformance.vue'
import ExperimentStats from './ExperimentStats.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    ExperimentPerformance,
    ExperimentStats,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return value.split('T')[0]
      }
      return ''
    },
  },
  props: {
    experiment: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('dataFilter', ['getGoalById']),
    goal() {
      return this.getGoalById(this.experiment.idgoal)
    },
    goalStatus() {
      // Calculate experiment status
      if (this.experiment.startDate > new Date().toISOString().substr(0, 10)) {
        return 'Planning'
      } if (this.experiment.startDate <= new Date().toISOString().substr(0, 10) && this.experiment.endDate >= new Date().toISOString().substr(0, 10)) {
        return 'Running'
      } if (this.experiment.endDate < new Date().toISOString().split('T')[0]) {
        return 'Ended'
      }
      return null
    },
    goalStatusBadgeVariant() {
      if (this.goalStatus === 'Planning') {
        return 'light-info'
      } if (this.goalStatus === 'Running') {
        return 'light-success'
      } if (this.goalStatus === 'Ended') {
        return 'light-danger'
      }
      return null
    },
    metrics() {
      return {
        expectedConversions: this.experiment.expectedConversions,
        investment: this.experiment.investment,
        conversions: this.experiment.conversions,
        // visits: this.experiment.visits,
      }
    },
  },
  methods: {
    ...mapActions('experimentList', ['setSelectedExperiments']),

    onGetLinksClick() {
      this.setSelectedExperiments(this.experiment.id)
    },
  },
}
</script>
