<template>
  <b-card-actions
    title="Experiment variants"
    no-actions
    no-body
  >
    <b-table
      striped
      borderless
      :fields="fields"
      :items="variants"
      responsive
      class="mb-0 position-relative"
      primary-key="name"
      show-empty
      empty-text="No matching records found"
    >
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <span
            v-if="data.item.urlUtm"
            v-clipboard:copy="data.item.urlUtm"
            v-b-tooltip.hover
            v-clipboard:success="onCopySuccess"
            title="Copy URL to clipboard"
            class="table-action-btn"
            role="button"
          >
            <feather-icon
              icon="LinkIcon"
              size="24"
              class="mr-1"
            />
          </span>

          <span
            v-if="isEditMode"
            v-b-tooltip.hover
            title="Duplicate this variant"
            class="table-action-btn"
            role="button"
            @click="duplicateEffort(data.item.index)"
          >
            <feather-icon
              icon="CopyIcon"
              size="24"
              class="mr-1"
            />
          </span>

          <span
            v-if="isEditMode"
            v-b-tooltip.hover
            title="Edit experiment variant"
            class="table-action-btn"
            role="button"
            @click="editEffort(data.item.index)"
          >
            <feather-icon
              icon="EditIcon"
              size="24"
              class="mr-1"
            />
          </span>

          <span
            v-if="isEditMode"
            v-b-tooltip.hover
            title="Remove experiment variant"
            class="table-action-btn btn-remove"
            role="button"
            @click="confirmRemove(data.item.index)"
          >
            <feather-icon
              icon="XIcon"
              size="24"
              class="mr-1"
            />
          </span>
        </div>
      </template>
    </b-table>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { BTable, VBTooltip } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    FeatherIcon,
    BCardActions,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  props: {
    variants: {
      type: Array,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    experimentName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Variant Name', sortable: true },
        { key: 'type', label: 'Channel', sortable: true },
        { key: 'source', label: 'Users source', sortable: true },
        { key: 'content', label: 'Content', sortable: true },
        { key: 'cost', label: 'Amount to spend', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
  methods: {
    ...mapActions('experimentEditor', [
      'removeEffort',
      'editEffort',
      'duplicateEffort',
    ]),

    confirmRemove(index) {
      this.$swal({
        title: 'Are you sure you want to remove?',
        text: 'This is not persisted until saving the experiment.',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then(response => {
        if (response.isConfirmed) {
          this.removeEffort(index)
        }
      })
    },
    onCopySuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Experiment URL copied',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';
.table-action-btn {
  cursor: pointer;
}
.table-action-btn:hover {
  cursor: pointer;
  color: $primary;
}

.table-action-btn.btn-remove:hover {
  color: $danger;
}

</style>
